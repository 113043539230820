import React from "react";
import { Col, Row } from "antd";

class ContentSales extends React.Component {
  render() {
    return (
      <div className="slide-content">
        <Row>
          <Col xs={24} lg={12}>
            <picture>
              <source
                type="image/webp"
                srcSet="https://s3-us-west-2.amazonaws.com/dayviewer/img/home/dvnext/contentslides/sales-manager-640.webp"
              />
              <source
                type="image/jpg"
                srcSet="https://s3-us-west-2.amazonaws.com/dayviewer/img/home/dvnext/contentslides/sales-manager-640.jpg"
              />
              <img
                src="https://s3-us-west-2.amazonaws.com/dayviewer/img/home/dvnext/contentslides/sales-manager-640.jpg"
                className="homepage-image"
                alt="online sales management"
                title="online sales management"
                height="100%"
                width="100%"
              />
            </picture>
          </Col>
          <Col xs={24} lg={12}>
            <h2>Sales Management</h2>
            <p>
              If you would like to clarify and track the progress of sales
              prospects and client potential for your entire sales team, a
              DayViewer Team Room can help.
            </p>
            <p>
              Within a DayViewer Team Room your sales team can create tasks
              against prospective clients and group and track those with labels
              and progress features. It would be a simple and efficient way to
              clearly track prospects that become clients. A great way to keep
              things organized and focussed.
            </p>
            <p>
              Use DayViewer to keep your sales management in control, we don't
              do the messaging part - that's up to you, but you can sure keep
              your sales team planned and more organized with DayViewer Team
              Rooms.
            </p>
          </Col>
        </Row>
      </div>
    );
  }
}

export default ContentSales;
