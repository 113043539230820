import React from "react";
import { Col, Row } from "antd";

class ContentBusiness extends React.Component {
  render() {
    return (
      <div className="slide-content">
        <Row>
          <Col xs={24} lg={12}>
            <picture>
              <source
                type="image/webp"
                srcSet="https://s3-us-west-2.amazonaws.com/dayviewer/img/home/dvnext/contentslides/online-business-calendar-640.webp"
              />
              <source
                type="image/jpg"
                srcSet="https://s3-us-west-2.amazonaws.com/dayviewer/img/home/dvnext/contentslides/online-business-calendar-640.jpg"
              />
              <img
                src="https://s3-us-west-2.amazonaws.com/dayviewer/img/home/dvnext/contentslides/online-business-calendar-640.jpg"
                className="homepage-image"
                alt="online business calendar"
                title="online business calendar"
                height="100%"
                width="100%"
              />
            </picture>
          </Col>
          <Col xs={24} lg={12}>
            <h2>Business &amp; Office Planner</h2>
            <p>
              DayViewer enables businesses of any size to improve their time
              management and workflows in any part of the business.
            </p>
            <p>
              Information management and team work should be simplified,
              uncomplicated and NOT a barrier to productivity and communication.
            </p>
            <p>
              Inherently, software shouldn't cause bottlenecks in operations. We
              believe simplifying the user interface enough for anyone to pick
              up and use is ideal for businesses, as there is no lost time due
              to software training.
            </p>
            <p>
              That is where the idea of having a central online calendar with a
              task management system and other useful information organizer
              tools can replace the plethora of tools that you may be currently
              using just to get your jobs organized and complete.
            </p>
            <p>
              Try DayViewer in your business and see how it can improve your
              teams communications and productivity, see the difference within
              days!
            </p>
          </Col>
        </Row>
      </div>
    );
  }
}

export default ContentBusiness;
