import React from "react";
import { Col, Row } from "antd";

class ContentDelegation extends React.Component {
  render() {
    return (
      <div className="slide-content">
        <Row>
          <Col xs={24} lg={12}>
            <picture>
              <source
                type="image/webp"
                srcSet="https://s3-us-west-2.amazonaws.com/dayviewer/img/home/dvnext/contentslides/online-work-scheduler-640.webp"
              />
              <source
                type="image/jpg"
                srcSet="https://s3-us-west-2.amazonaws.com/dayviewer/img/home/dvnext/contentslides/online-work-scheduler-640.jpg"
              />
              <img
                src="https://s3-us-west-2.amazonaws.com/dayviewer/img/home/dvnext/contentslides/online-work-scheduler-640.jpg"
                className="homepage-image"
                alt="online team task manager"
                title="online team task manager"
                height="100%"
                width="100%"
              />
            </picture>
          </Col>
          <Col xs={24} lg={12}>
            <h2>Online Team Tasks Management</h2>
            <p>
              Are you looking for a way to set and manage tasks to your staff
              and other resources?
            </p>

            <p>
              DayViewer Team Rooms helps you track progress and to manage
              employee, contract or external resources effectively.
            </p>
            <p>
              Create an empowering environment where employees can openly
              communicate issues or problems against tasks, and where everyone
              can contribute and work together to find the optimal solution.
            </p>
            <p>
              DayViewer provides one of the easiest to implement and most
              efficient solutions to task management in a team environment - all
              based around a shared calendar, planner and organizer.
            </p>
          </Col>
        </Row>
      </div>
    );
  }
}

export default ContentDelegation;
