import React, { Component } from "react";
import { List, Card } from "antd";
import {
  BookOutlined,
  CalendarOutlined,
  ContainerOutlined,
  PaperClipOutlined,
  ProjectOutlined,
  TeamOutlined,
} from "@ant-design/icons";

const data = [
  {
    title: (
      <span>
        <CalendarOutlined /> Team Calendar
      </span>
    ),
    content:
      "A team calendar can help visualize your day with the other people in your team. Things can be organized and planned together in a team.",
    url: "/dayviewer.com",
  },
  {
    title: (
      <span>
        <ContainerOutlined /> Work Scheduler
      </span>
    ),
    content:
      "For task management, DayViewer provides a centralised way to assign tasks and jobs to team members.",
  },
  {
    title: (
      <span>
        <PaperClipOutlined /> Content Calendar
      </span>
    ),
    content:
      "DayViewer can be used as a content management system and as a content calendar - your marketing team can upload documents and write posts, with a team room, things can be scheduled together.",
  },
  {
    title: (
      <span>
        <BookOutlined /> Office Diary
      </span>
    ),
    content:
      "If you just need one centralised place where all company information is. DayViewer can actually be your Office Diary System. It can assist in whichever way your business requires.",
  },
  {
    title: (
      <span>
        <TeamOutlined /> Staff Scheduler
      </span>
    ),
    content:
      "Does your staff need to be on rotation or other cycles? - use DayViewer to notify staff of changes in their stations or times.",
  },
  {
    title: (
      <span>
        <ProjectOutlined /> Simple Project Management
      </span>
    ),
    content:
      "DayViewer is perfect for teams that need to manage tasks in a project environment. You may already use project management software, but you can complement that with DayViewer for more efficient task management.",
  },
];

class FeatureGrid extends Component {
  render() {
    return (
      <Card className="feature-grid-background" bordered={false}>
        <List
          grid={{
            gutter: 8,
            xs: 1,
            sm: 2,
            md: 2,
            lg: 2,
            xl: 3,
            xxl: 6,
          }}
          dataSource={data}
          renderItem={(item) => (
            <List.Item>
              <Card
                title={item.title}
                // bordered={false}
                className="feature-grid-cards"
              >
                {item.content}{" "}
                {/* <p>
                  <a href={item.url}>Screencast</a>
                </p> */}
              </Card>
            </List.Item>
          )}
        />
      </Card>
    );
  }
}

export { FeatureGrid };
