import React from "react";
import { Col, Row } from "antd";

class ContentProjects extends React.Component {
  render() {
    return (
      <div className="slide-content">
        <Row>
          <Col xs={24} lg={12}>
            <picture>
              <source
                type="image/webp"
                srcSet="https://s3-us-west-2.amazonaws.com/dayviewer/img/home/dvnext/contentslides/online-task-planner-640.webp"
              />
              <source
                type="image/jpg"
                srcSet="https://s3-us-west-2.amazonaws.com/dayviewer/img/home/dvnext/contentslides/online-task-planner-640.jpg"
              />
              <img
                src="https://s3-us-west-2.amazonaws.com/dayviewer/img/home/dvnext/contentslides/ponline-task-planner-640.jpg"
                className="homepage-image"
                alt="simple project management software"
                title="simple project management software"
                height="100%"
                width="100%"
              />
            </picture>
          </Col>
          <Col xs={24} lg={12}>
            <h2>Simple Project Management</h2>
            <p>
              Would your team or business benefit from a low learning curve,
              intuitive and clear team collaboration and simple project system
              which allows your project to be planned effectively and meet
              deadlines?
            </p>
            <p>
              At DayViewer we have been rethinking team collaboration and simple
              project management and have created Team Rooms. Central to a team
              room is the team calendar where everyone in the team can see what
              is happening, and when.
            </p>
            <p>
              Get your whole team working on one central workspace, in a 'noise
              reduced' system - remove the need for constant progress meetings
              and other timesinks and get your projects done on time &amp;
              hassle free.
            </p>
          </Col>
        </Row>
      </div>
    );
  }
}

export default ContentProjects;
