import React, { Component } from "react";
import { Card } from "antd";
import { RightOutlined, LeftOutlined } from "@ant-design/icons";
import Slider from "react-slick";

import ContentBusiness from "../../pages/homepage-content/contentBusiness";
import ContentDelegation from "../../pages/homepage-content/contentDelegation";
import ContentManagement from "../../pages/homepage-content/contentManagement";
import ContentSales from "../../pages/homepage-content/contentSales";
import ContentProjects from "../../pages/homepage-content/contentProjects";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      // className={className}
      style={{
        ...style,
        display: "block",

        height: "100px",
        right: "-25px",
        position: "absolute",
        top: "30%",
        padding: "3px",
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      <RightOutlined style={{ marginTop: "38px" }} />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      // className={className}
      style={{
        ...style,
        display: "block",

        height: "100px",
        left: "-25px",
        position: "absolute",
        top: "30%",
        padding: "3px",
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      <LeftOutlined style={{ marginTop: "38px" }} />
    </div>
  );
}

class BusinessContent extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
    };
    return (
      <Card
        style={{
          marginTop: 10,
          marginBottom: 10,
          padding: 10,
          width: "100%",
          background: "#ffffff",
          border: "none",
        }}
        title="See how DayViewer helps thousands every day"
      >
        <div>
          <Slider {...settings}>
            <div>
              <ContentBusiness />
            </div>
            <div>
              <ContentDelegation />
            </div>
            <div>
              <ContentManagement />
            </div>
            <div>
              <ContentSales />
            </div>
            <div>
              <ContentProjects />
            </div>
          </Slider>
        </div>
      </Card>
    );
  }
}

export { BusinessContent };
