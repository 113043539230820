import React from "react";
import { Link } from "gatsby";
import { Row, Col, Layout, Button, List, Card, BackTop, Image } from "antd";
import { Helmet } from "react-helmet";

import CommonHeader from "../components/common/header";
import CommonFooter from "../components/common/footer";

import {
  FreePlanList,
  ProPlanList,
  TeamPlanList,
} from "../components/plans/plans";
import { BusinessContent } from "../components/businessLanding/sliderContent";
import { FeatureGrid } from "../components/businessLanding/featureGrid";

const { Content } = Layout;

class BusinessPage extends React.Component {
  render() {
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="DayViewer Online Team Calendar Planner for Business - Create a team calendar, collaborate better in your team - Task management and job scheduling solution around an Online Team Calendar Planner"
          />
          <title>DayViewer Online Calendar for Business</title>
        </Helmet>
        <Layout>
          <CommonHeader />
        </Layout>
        <Layout style={{ minHeight: "100vh" }}>
          <BackTop />
          <Content className="homepage-background">
            <Row>
              <div className="homepage-banner">
                <Col lg={12}>
                  <div className="homepage-jumbotron">
                    <div className="homepage-headlines">
                      <h1>
                        Effective, Efficient Business &amp; Team Management
                        Solution
                      </h1>
                      <h2>
                        Online Team Calendar &amp; Planner System For Task
                        Management, Information Organization and Work Planning
                      </h2>
                      <p>
                        <strong>DayViewer</strong> helps improve team
                        information management, productivity and planning by
                        enabling collaboration around a team calendar, task
                        scheduler &amp; workspace.
                      </p>
                    </div>
                    {/* <div className="business-signup-btn-position"> */}
                    <div style={{ padding: "0 0 20px 0" }}>
                      <Button className="homepage-signup-btn">
                        <a
                          href="#"
                          onClick={() =>
                            window.open(
                              "https://app.dayviewer.com/auth/signup",
                              "_self"
                            )
                          }
                        >
                          Create Your Team Room
                        </a>
                      </Button>
                    </div>
                    {/* <span>
                     
                      <Link to="/landing/work-dashboard">
                        More Details of Our Work Planner Solution Here >>
                      </Link>
                    </span> */}
                  </div>
                </Col>
                <Col lg={12}>
                  <div>
                    {/* <picture>
                      <source
                        type="image/webp"
                        srcSet="https://s3-us-west-2.amazonaws.com/dayviewer/img/home/dvnext/landing/DayViewer-FastPlanner-Teams-Image.webp"
                      ></source>
                      <source
                        type="image/jpg"
                        srcSet="https://s3-us-west-2.amazonaws.com/dayviewer/img/home/dvnext/landing/DayViewer-FastPlanner-Teams-Image.png"
                      ></source>
                      <img
                        src="https://s3-us-west-2.amazonaws.com/dayviewer/img/home/dvnext/landing/DayViewer-FastPlanner-Teams-Image.png"
                        className="homepage-image"
                        alt="online business calendar"
                        title="online business calendar"
                      />
                    </picture> */}
                    <Image
                      width="100%"
                      height="100%"
                      src="https://s3-us-west-2.amazonaws.com/dayviewer/img/home/dvnext/landing/DayViewer-FastPlanner-Teams-Image.png"
                    />
                  </div>
                </Col>
              </div>
            </Row>
            <Row>
              <div
                className="homepage-banner"
                style={{ textAlign: "left", padding: "2em" }}
              >
                <Col lg={12} xs={24}>
                  {/* <LandingTeamSlider /> */}
                  <video
                    preload="none"
                    controls="controls"
                    poster="https://dayviewer.s3-us-west-2.amazonaws.com/img/home/dvnext/landing/DayViewer-Team-Ad-Poster.jpg"
                    // muted="muted"
                    // autoplay="autoplay"
                    // loop="loop"
                    width="100%"
                    name="DayViewer Online Calendar Planner"
                    src="https://res.cloudinary.com/dayviewer1/video/upload/v1669256364/DV-Team-Room-Ad_ysgc1g.mp4"
                  />
                </Col>
                <Col lg={12} xs={24}>
                  <div style={{ padding: 20 }}>
                    <div>
                      <h1>Online Team Calendar Planner System</h1>
                      <h2>
                        Consolidate your productivity into one app to work more
                        efficiently.
                      </h2>

                      <p>
                        Say goodbye to connecting multiple random apps which
                        might change or shut down over time. If you are looking
                        for the closest solution to an all in one collaborative
                        task management solution check out DayViewer Team Rooms.
                      </p>
                      <p>
                        Our online team planner allows your business, office or
                        project team to spin up a room and work together around
                        one task management system - this makes your team more
                        cohesive, and more efficient.
                      </p>
                      <p>
                        Comment directly on tasks to avoid change of subject
                        matter (which can often happen with team chat), think of
                        it more like comments on social media posts... but for
                        your tasks!
                      </p>
                      <p>
                        We keep your information organized, safe and together in
                        one place (our database storage is encrypted, backed up
                        and we use SSL data transport as standard throughout the
                        app).
                      </p>
                      <p>
                        Get your team organized - management of tasks is
                        industry agnostic and you can use DayViewer for any
                        project, work scheduling or coordination activities.
                        We've been used by almost every type of organization
                        from blue chip, space and technology companies through
                        to family businesses, established small / medium
                        businesses and startups.
                      </p>
                      <p>
                        Keep your tasks organized and importantly give your team
                        the chance to run much more efficiently around one
                        calendar planner system!
                      </p>
                      <p>
                        Get started and take a 14 day trial, and your team will
                        be working in sync together in no time at all.
                      </p>
                      <div style={{ textAlign: "center" }}>
                        <Button className="homepage-signup-btn">
                          <a
                            href="#"
                            onClick={() =>
                              window.open(
                                "https://app.dayviewer.com/auth/signup",
                                "_self"
                              )
                            }
                          >
                            Get Started - Sign Up Free
                          </a>
                        </Button>
                      </div>
                    </div>
                  </div>
                </Col>
              </div>
            </Row>

            <Row>
              <FeatureGrid />
            </Row>

            <div className="homepage-banner">
              <BusinessContent />
            </div>
          </Content>
        </Layout>
        <Layout>
          <CommonFooter />
        </Layout>
      </div>
    );
  }
}

export default BusinessPage;
